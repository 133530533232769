import BaseService from './baseService'
import omit from "lodash/omit";
import get from "lodash/get";
import api from '../utils/api'
import { ItemMeta } from '../models'

const HERO_IMAGE_MODE = {
  NONE: 0,
  NORMAL: 1,
  FEATURE: 2
}

const HERO_IMAGE_MODE_LABELS = [
  { value: HERO_IMAGE_MODE.NONE, text: 'Нет' },
  { value: HERO_IMAGE_MODE.NORMAL, text: 'Обычное' },
  { value: HERO_IMAGE_MODE.FEATURE, text: 'Фичер' }
]

const STATUS = {
  DRAFT: 0,
  PUBLISHED: 1
}

const STATUS_LABELS = [
  { value: STATUS.DRAFT, text: 'Черновик' },
  { value: STATUS.PUBLISHED, text: 'Опубликовано' }
]

class PostService extends BaseService {
  get url () {
    return 'blog/posts'
  }

  get heroImageModes () {
    return HERO_IMAGE_MODE
  }

  get heroImageModeLabels () {
    return HERO_IMAGE_MODE_LABELS
  }

  get statuses () {
    return STATUS
  }

  get statusLabels () {
    return STATUS_LABELS
  }

  copy (postId) {
    return api.post(`blog/posts/${postId}/copy`)
  }

  createModel () {
    return {
      title: '',
      heroImage: null,
      heroImageMode: HERO_IMAGE_MODE.NONE,
      excerpt: '',
      contentSource: '',
      status: STATUS.DRAFT,
      author: null,
      speciality: null,
      organization: null,
      showOnAuthorPage: true,
      showOnClinicPage: true,
      symptoms: null,
      diseases: null,
      procedures: null,
      meta: new ItemMeta(),
    }
  }

  toDto (entity) {
    const symptoms = entity.symptoms || []
    const diseases = entity.diseases || []
    const procedures = entity.procedures || []
    let n = omit(entity, ['heroImage', 'author', 'speciality', 'symptoms', 'diseases', 'procedures'])
    n.heroImageId = get(entity, 'heroImage.id', null)
    n.authorId = get(entity, 'author.id', null)
    n.specialityId = get(entity, 'speciality.id', null)
    n.organizationId = get(entity, 'organization.id', null)
    n.symptomIds = symptoms.map(({ id }) => id)
    n.diseaseIds = diseases.map(({ id }) => id)
    n.procedureIds = procedures.map(({ id }) => id)
    n.showOnAuthorPage = entity.showOnAuthorPage
    n.showOnClinicPage = entity.showOnClinicPage

    return n
  }
}

export default new PostService()

